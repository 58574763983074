.btn-toggle-zi {
    background-color: var(--color-bg-stats);
    color: var(--color-bg);
    position: absolute;
    bottom: 5px;
    height: 28px;
    width: 36px;
    display: block;
    z-index: 1;
    border-radius: 50%;
    border-width: 0;
    padding: 0;
}

.zi-on {
    background-color: var(--color-cyan);
}

/*#zi-2019{*/
/*    left: 35px;*/
/*    font-size: 11px;*/
/*}*/

#zi-2{
    left: 35px;
}
#zi-20{
    left: 75px;
}
#zi-100{
    left: 115px;
}
