@media only screen and (max-width: 1024px) {
    /*button#btn_2019,*/
    button#btn_hier,
    button#btn_temps_reel,
    button#btn_j1,
    button#btn_j2,
    button#btn_j3,
    button#btn_j4,
    button#btn_j5,
    button#btn_j6,
    button#btn_j7,
    button#btn_CPPRO {
        padding: 0 0.625rem !important;
    }
}

/*button#btn_2019,*/
button#btn_hier,
button#btn_temps_reel,
button#btn_j1,
button#btn_j2,
button#btn_j3,
button#btn_j4,
button#btn_j5,
button#btn_j6,
button#btn_j7,
button#btn_CPPRO {
    width: auto;
    height: 2rem;
    /*background-color: var(--color-green);*/
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 6px;
    padding: 0 1.25rem;
    margin: 0.3125rem;
    font-size: 0.75rem;
    color: white;
}

/*button#btn_hier {*/
/*    background-color: #00d0c2;*/
/*}*/

/*button#btn_temps_reel {*/
/*    background-color: #00a79e;*/
/*}*/

/*button#btn_j1,*/
/*button#btn_j2,*/
/*button#btn_j3,*/
/*button#btn_j4,*/
/*button#btn_j5,*/
/*button#btn_j6 {*/
/*    background-color: var(--color-green);*/
/*}*/



/*button#btn_2019:disabled,*/
button#btn_hier:disabled,
button#btn_temps_reel:disabled,
button#btn_j1:disabled,
button#btn_j2:disabled,
button#btn_j3:disabled,
button#btn_j4:disabled,
button#btn_j5:disabled,
button#btn_j6:disabled,
button#btn_j7:disabled {
    background-color: var(--color-disabled);
}

/*.last-update {*/
/*    width: auto;*/
/*    height: 2rem;*/
/*    !*padding: 0 1.25rem;*!*/
/*    margin-top: 0.6rem;*/
/*    font-size: 0.75rem;*/
/*    color: #00a79e;*/
/*    float: right;*/
/*}*/

.header_logo {
    /*width: 200px;*/
    /*width: 450px;*/
    /*margin: 0.9375rem;*/
    /*height: 60px;*/
    max-height: 60px;
    margin-top: -5px;
    /*margin: 0.01rem;*/
}

@media only screen and (max-width: 815px) {
    .header_logo {
        width: 300px !important;
    }
}

.span-debit {
    font-size: 9px;
    pointer-events: none;
}
.previsionDiv {
    position: fixed;
    font-size: 9pt;
    padding: 10px;
    /*padding-top: 0;*/
    top: 230px;
    right: 10px;
    width: 265px;
    height: 145px;
    background-color: var(--color-bg);
    border-radius: 6px;
}
.previsionP {
    font-weight: bold;
    margin: 0;
}
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}