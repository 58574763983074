#searchDiv {
    position: absolute;
    display: none;
    background-color: var(--color-bg);
    right: 0;
    top: -94px;
    width: 290px;
    height: 94px;
    padding: 0.2rem 0.5rem;
    border-radius: 6px;
    z-index: 1;
    text-align: left;
}

#alertDiv{
    position: absolute;
    display: none;
    background-color: var(--color-bg);
    right: 0;
    top: -70px;
    width: 120px;
    height: 64px;
    padding: 0.2rem 0.5rem;
    border-radius: 6px;
    z-index: 1;
    text-align: left;
}

.title-recherche {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.875rem;
    color: var(--color-light-black);
    margin: 0.3125rem 0 0.3125rem 0;
}

.recherche_container {
    width: 300px
    /*display: flex;*/
    /*justify-content: space-between;*/
}

#attSelect {
    height: 32px;
    width: 155px;
}

.btn-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    color: var(--color-bg);
    background-color: var(--color-cyan);
    border: none;
    border-radius: 6px;
    margin: 6px;
}

#searchBtn {
    right: 81px;
    top: 21px;
}

#pdfBtn {
    right: 42px;
    top: 21px;
}
#smsBtn {
    left: 18px;
    top: 21px;
}

#courrielBtn {
    right: 18px;
    top: 21px;
}

#alertBtn {
    right: 5px;
    top: 21px;
}

#alertBtn:enabled {
    background-color: var(--color-red);
}

#alertBtn:disabled {
    background-color: var(--color-disabled);
}

.print_results_container {
    margin: 0;
}

#printResults {
    position: absolute;
    bottom: 12px;
    font-family: var(--font-prompt-italic);
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--color-medium-grey);
}



