#btn-toggle-photo{
    background-color: transparent;
    color:white;
    position: absolute;
    bottom: 5px;
    left: 5px;
    height: 28px;
    width: 28px;
    display: block;
    z-index: 1;
    border-width: 0;
    padding: 0;
}
