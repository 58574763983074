table,
td {
    border: 1px solid #333;
}

thead,
tfoot {
    background-color: #333;
    color: #fff;
}

.enun-popup {
    text-align: left;
}

.model-container {
    position: relative;
}

.enun-popup-title {
    font-size: 14px;
    font-weight: bold;
    margin: 0 !important;
    padding: 6px 7px;
}

.model-text {
    position: absolute;
    top: 8px;
    left: 100px;
    font-size: 14px;
}

#model-image {
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 5px;
}

.esri-popup__header-title {
    text-align: left;
    margin: 0 !important;
    padding-left: 22px !important;
    height: 30px !important;
}

.esri-popup__header {
    padding: 0 !important;
    height: 30px !important;
}