.graph {
    background-color: var(--color-bg);
    height: auto;
    width: 100%;
    bottom: 14rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}
@media only screen and (min-width: 812px) {
    .graph {
        bottom: 11.1rem;
    }
}

@media only screen and (max-width: 815px) {
    .graph {
        right: 0;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 816px) and (max-width: 1024px) {
    .graph {
        right: 0;
        margin: 0 auto;
        bottom: 14rem;
    }
}

@media only screen and (max-width: 600px) {
    .graph {
        bottom: 10.2rem;
        top: auto;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1276px) {
    .graph {
        bottom: 11.1rem;
    }

}