#validation-menu {
    position: absolute;
    display: none;
    background-color: var(--color-bg);
    left: 5px;
    bottom: 80px;
    width: 158px;
    height: 38px;
    padding: 0.2rem 0.5rem;
    border-radius: 6px;
    z-index: 1;
    text-align: left;
}

.btn-toggle-validation {
    /*background-color: var(--color-bg-stats);*/
    background-color: transparent;
    color: var(--color-bg);
    position: absolute;
    bottom: 42px;
    left: 40px;
    height: 28px;
    width: 28px;
    display: block;
    z-index: 1;
    /*border-radius: 50%;*/
    border-width: 0;
    padding: 0;
}